<template>
  <div class="back-link">
    <router-link class="stretched-link" :to="to">
      <span class="back-link__icon"><Icon icon="arrow-left" /></span> {{ text }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    text: {
      type: String,
    },
  },
};
</script>

<style scoped>
.back-link {
  margin-bottom: 30px;
  font-weight: normal;
}

.back-link a {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.back-link__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  margin-right: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  transition: border 0.1s;
}

body.-notouch .back-link:hover {
  color: #205640;
}

body.-notouch .back-link:active {
  color: #205640;
}

body.-notouch .back-link:hover .back-link__icon {
  border-color: #a6bbb3;
}

body.-notouch .back-link:active .back-link__icon {
  border-color: #205640;
}

body.-notouch .text-white .back-link:hover {
  color: inherit;
}

body.-notouch .text-white .back-link:active {
  color: inherit;
}

body.-notouch .text-white .back-link:hover .back-link__icon {
  border-color: currentColor;
}

body.-notouch .text-white .back-link:active .back-link__icon {
  border-color: currentColor;
}
@media (max-width: 575px) {
  .back-link.-small {
    margin-top: -40px;
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
  }

  .back-link.-small a {
    display: flex;
    padding: 12px 0;
  }

  .back-link.-small .back-link__icon {
    width: auto;
    height: auto;
    margin-right: 10px;
    border: 0;
  }

  .back-link.-small .back-link__icon svg {
    width: 17px;
    height: 12px;
  }
}
</style>
