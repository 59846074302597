<template>
  <Head>
    <title>Выбор этажа {{ $titleEnd }}</title>
    <meta property="og:title" :content="'Выбор этажа ' + $titleEnd" />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>
  <main class="main my-0" ref="main">
    <div class="plan-building">
      <div class="top text-white">
        <div class="container">
          <Backlink :to="{ name: 'PlanArchive' }" text="Выбор корпуса" />
          <h1 class="h2">
            Выбор<br />
            этажа
          </h1>
          <router-link
            :to="{ name: 'PlanSearch' }"
            class="btn btn-small btn-yellow"
          >
            <Icon icon="settings" />Подбор по параметрам
          </router-link>
        </div>
      </div>
      <div class="background">
        <img
          loading="lazy"
          width="2160"
          height="1350"
          :src="require('@i/plan-building-' + $route.params.korp + '.jpg')"
          alt="План корпуса"
          @load="mainImageLoaded = true"
        />
      </div>
      <div v-if="dots" class="tooltip__wrap">
        <svg
          v-if="mainImageLoaded"
          class="tooltip__area"
          viewBox="0 0 2160 1350"
        >
          <polygon
            v-for="dot in dots"
            :key="dot.id"
            :class="{ active: dot.isActive }"
            :points="dot.points"
            @click="
              $router.push({
                name: 'PlanFloor',
                params: { korp: $route.params.korp, floor: dot.id },
              })
            "
            @mouseenter="
              pathMove($event, dot);
              dot.isActive = true;
            "
            @mouseleave="dot.isActive = false"
            @mousemove="pathMove($event, dot)"
          />
        </svg>
        <div
          v-for="dot in dots"
          :key="dot.id"
          class="tooltip__dot"
          :class="{ active: dot.isActive }"
          :style="{
            transform: 'translate3d(' + dot.x + 'px, ' + dot.y + 'px, 0px)',
          }"
        >
          <div class="tooltip__popup">
            <div class="h4">{{ dot.id }} этаж</div>
            <div class="tooltip-table">
              <div class="tooltip-table__row">
                <div class="tooltip-table__td">
                  <span>Квартиры</span>
                </div>
                <div class="tooltip-table__td">
                  <span>{{ dot.flats }}</span>
                </div>
              </div>
              <div v-if="dot.min > 0" class="tooltip-table__row">
                <div class="tooltip-table__td">
                  <span>Площадь, м&sup2;</span>
                </div>
                <div class="tooltip-table__td">
                  <span>{{ dot.min }} - {{ dot.max }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import _forEach from "lodash/forEach";
import _toNumber from "lodash/toNumber";
import _minBy from "lodash/minBy";
import _maxBy from "lodash/maxBy";
import _groupBy from "lodash/groupBy";
import Backlink from "@/components/UI/Backlink.vue";
import { gsap } from "gsap";
import { Head } from "@vueuse/head";

export default {
  components: {
    Head,
    Backlink,
  },
  data() {
    return {
      mainImageLoaded: false,
      dots: null,
      points: {
        1: {
          3: "948,1199,1067,1192,1324,1230,1324,1327,1086,1346,945,1339",
          4: "912,1203,916,1131,1069,1108,1322,1171,1324,1226,1074,1188",
          5: "884,1127,885,1052,1074,1022,1324,1110,1326,1167,1071,1102",
          6: "853,1052,855,978,1076,934,1326,1049,1326,1108,1076,1022",
          7: "853,972,851,902,1076,850,1326,987,1322,1043,1072,930",
          8: "851,896,853,829,1072,759,1324,923,1324,980,1076,846",
          9: "851,749,1069,676,1328,867,1326,925,1072,753,857,823",
          10: "855,675,1074,591,1326,810,1326,864,1071,675,855,745",
          11: "857,597,1071,501,1326,749,1324,805,1072,589,857,673",
          13: "853,522,1074,415,1326,688,1324,748,1069,497,855,595",
          14: "853,451,1076,330,1313,611,1313,666,1072,412,857,519",
          15: "851,372,1071,246,1298,527,1298,590,1071,328,855,443",
          16: "857,298,1071,162,1282,441,1282,504,1065,246,851,367",
          17: "857,290,859,237,1069,118,1252,342,1252,393,1067,162",
          19: "851,155,1074,0,1257,274,1250,331,1069,115,857,237",
        },
        2: {
          21: "851,372,1071,246,1298,527,1298,590,1071,328,855,443",
        },
      },
    };
  },
  computed: {
    animateReady() {
      const { dots, mainImageLoaded } = this;
      return {
        dots,
        mainImageLoaded,
      };
    },
  },
  watch: {
    animateReady: {
      handler: function (val) {
        if (val.dots != null && val.mainImageLoaded) {
          this.$nextTick(() => {
            let polygons = this.$refs.main.querySelectorAll(
              ".tooltip__area polygon"
            );
            if (polygons.length > 0) {
              gsap.fromTo(
                polygons,
                { opacity: 0, y: -3, transitionDuration: 0 },
                {
                  opacity: 0.4,
                  y: 0,
                  clearProps: "all",
                  duration: 0.5,
                  stagger: { each: 0.05, from: "center" },
                }
              );
            }
          });
        }
      },
    },
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint +
          "building-objects/" +
          this.$project +
          "/properties?building=" +
          this.$route.params.korp
      );

      const result = [];
      const list = _groupBy(response.data.data, "floor.number");

      _forEach(this.points[this.$route.params.korp], (value, key) => {
        // данные points первичны
        const item = list[key];
        result.push({
          id: _toNumber(key),
          flats: item === undefined ? 0 : item.length,
          min: item === undefined ? 0 : _minBy(item, "layout.area").layout.area,
          max: item === undefined ? 0 : _maxBy(item, "layout.area").layout.area,
          points: value,
          isActive: false,
          x: 0,
          y: 0,
        });
      });

      // _forEach(list, (value, key) => { // данные api первичны
      // 	const item = this.points[this.$route.params.building][_toNumber(key)];
      // 	result.push({
      // 		id: _toNumber(key),
      // 		flats: value.length,
      // 		min: _minBy(value, 'layout.area').layout.area,
      // 		max: _maxBy(value, 'layout.area').layout.area,
      // 		points: item === undefined ? null : item || null,
      // 		isActive: false,
      // 		x: 0,
      // 		y: 0
      // 	});
      // });

      this.dots = result;

      //console.log(result);
    } catch (err) {
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
  methods: {
    pathMove(e, dot) {
      dot.x = e.offsetX;
      dot.y = e.offsetY;
    },
  },
};
</script>
<style scoped>
.plan-building {
  position: relative;
}

.background {
  position: relative;
  z-index: -1;
  padding-bottom: 62.5%;
  background: #b6acaa;
}

.background::-webkit-scrollbar {
  display: none;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 33%;
  background: linear-gradient(
    0deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.5) 100%
  );
  pointer-events: none;
}

.background::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 33%;
  background: linear-gradient(
    180deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.5) 100%
  );
  pointer-events: none;
}

.background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: auto;
}

.tooltip__popup {
  bottom: 25px;
}

.top {
  position: absolute;
  top: 110px;
  left: 0;
  z-index: 2;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  pointer-events: none;
}

.top h1,
.top .h1 {
  margin: 0 0 30px;
  line-height: 0.875;
}

.top .btn {
  pointer-events: auto;
}
</style>
